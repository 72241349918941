.activity-indicator__label {
	padding-left: 5px;
	vertical-align: middle;
	color: #888;
	font-size: 12px;
}

.activity-indicator__three-dot {
	display: inline-block;
}

.activity-indicator__three-dot__dot {
	width: 10px;
	height: 10px;
	background-color: #00B4F0;

	border-radius: 100%;
	display: inline-block;
	animation: activity-indicator__three-dot__dot 1.4s infinite ease-in-out;
	/* Prevent first frame from flickering when animation starts */
	animation-fill-mode: both;
}

.activity-indicator__three-dot__dot:nth-child(1) {
	animation-delay: -0.32s;
}

.activity-indicator__three-dot__dot:nth-child(2) {
	animation-delay: -0.16s;
}

@keyframes activity-indicator__three-dot__dot {
	0%, 80%, 100% { transform: scale(0.0); }
	40% { transform: scale(1.0); }
}

.activity-indicator__spinner {
	box-sizing: initial;
	width: 50px;
	height: 50px;
	display: inline-block;
	border: 1.1em solid #adadad;
	border-left-color: white;
	border-width: 6px;
	border-radius: 50%;
	animation: activity-indicator__spinner 0.6s infinite linear;
}

@keyframes activity-indicator__spinner {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
